<template>
  <div class="PaasCustomized">
    <BannerVideo :video-url="$StaticFileUrl + '/mp/video/customer-service-video_2.mp4'">
      <div class="title wow fadeInDown" data-wow-duration="1s">PaaS定制服务</div>
      <div class="desc wow fadeInDown" data-wow-duration="1s">基于万鲸云框架，为企业进行私有化定制开发</div>
      <div class="flex-row flex-col-center">
        <div class="btn-red wow flipInX" data-wow-duration="1s" style="margin-top: 100px">立即体验</div>
      </div>
    </BannerVideo>
  </div>
</template>

<script>
import WOW from 'wowjs'
import BannerVideo from '@/components/BannerVideo'

/**
 * @author  XuHongli
 * @date  2022/12/28 10:12
 * @version 1.0
 * @description
 */
export default {
  name: 'PaasCustomized',
  components: {BannerVideo},
  mounted() {
    new WOW.WOW().init()
  }
}
</script>

<style scoped>

</style>
